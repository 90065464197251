import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "goldriverstar",
  casinoId: 262,
  GA_TRACKING:'G-EMC2V90TXK',

  rivalChatGroupName: "Gold River Star",
  prettyName: "Gold River Star",
  contactEmail: "support@goldriverstar.com",
  docsEmail: "documents@goldriverstarcasino.email",

  publicPromotions: [
    {id: 'PARADISE8_1', name: "deposit", state: "eligible", details: "welcome", imgPath: "assets/brands/goldriverstar/promo.png"},
    {id: 2, name: "cashbackInsurance", state: "eligible", details: "welcome", imgPath: "assets/brands/goldriverstar/promo.png"},
    {id: 'PARADISE8_3', name: "nextDayCashback", state: "eligible", details: "weekly", imgPath: "assets/brands/goldriverstar/promo.png"},
    {id: 'PARADISE8_4', name: "loyalty", state: "eligible", details: "weekly", imgPath: "assets/brands/goldriverstar/promo.png"}
  ],


  //SEO
  metaDescription: "Join Gold River Star Casino for a premier gaming experience with top slots, table games, and live dealer options. Enjoy generous bonuses, a rewarding loyalty program, and secure play on desktop or mobile. Gold River Star Online casino, your best option!",
  logoName: "gold-river-star-casino-online-casino-logo.png",
  logoAltText: "Gold River Star Casino online logo with rose gold vintage-style text and star emblem known for slots poker and exclusive casino rewards."
};


